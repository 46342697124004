import { default as styled } from 'styled-components'

const StyledErrorPageContainer = styled.div`
  flex-direction: column;
  max-width: 600px;
`

const StyledErrorPageSpacing = styled.div`
  margin-bottom: var(--vel-fixed-spacing-07);
`

const ErrorPage = () => {
  return (
    <StyledErrorPageContainer>
      <StyledErrorPageSpacing>
        <h1>Not found</h1>
        <p>Requested page was not found</p>
      </StyledErrorPageSpacing>
    </StyledErrorPageContainer>
  )
}

export default ErrorPage
